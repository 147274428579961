exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.48.46.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__Button_default-icon .btn-icon:before{background-color:#fff!important;opacity:.1}", ""]);

// exports
exports.locals = {
	"default-icon": "ImageSliderContentBlock__Button_default-icon",
	"defaultIcon": "ImageSliderContentBlock__Button_default-icon"
};