import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from 'sana/elements';
import classNames from 'classnames';
import styles from './Button.module.scss';

const Button = ({
  buttonText,
  buttonAltText,
  buttonTextColor,
  buttonBackgroundColor,
  buttonBottomBorderColor,
  buttonBackgroundHoverColor,
  buttonBottomBorderHoverColor,
  buttonLink,
  tabIndex,
}) => {
  const [hover, toggleHover] = useState(false);
  const [customStyle, setCustomStyle] = useState(null);

  const wcagButtonAttributes = {
    'aria-label': buttonAltText ? buttonAltText : undefined,
    tabIndex,
  };

  useEffect(() => {
    let customStyle = {
      color: buttonTextColor && buttonTextColor,
      backgroundColor: buttonBackgroundColor && buttonBackgroundColor,
      boxShadow:
        buttonBottomBorderColor && `inset 0 -1px 0 ${buttonBottomBorderColor}`,
    };

    if (hover) {
      customStyle = {
        ...customStyle,
        backgroundColor: buttonBackgroundHoverColor && buttonBackgroundHoverColor,
        boxShadow:
          buttonBottomBorderHoverColor &&
          `inset 0 -1px 0 ${buttonBottomBorderHoverColor}`,
      };
    }
    setCustomStyle(customStyle);
  }, [hover]);

  return buttonText ? (
    <LinkButton
      {...wcagButtonAttributes}
      {...buttonLink}
      style={customStyle}
      onMouseEnter={() => toggleHover(!hover)}
      onMouseLeave={() => toggleHover(!hover)}
      className={classNames('image-slider-button-click', customStyle?.backgroundColor ? styles.defaultIcon : '')}
    >
      {buttonText}
    </LinkButton>
  ) : null;
};

Button.propTypes = {
  buttonText: PropTypes.string,
  buttonAltText: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonBottomBorderColor: PropTypes.string,
  buttonBackgroundHoverColor: PropTypes.string,
  buttonBottomBorderHoverColor: PropTypes.string,
  buttonLink: PropTypes.object,
  tabIndex: PropTypes.number,
};

export default Button;