exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.48.46.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__ImageSlider_vd-min-height{background-color:var(--adk-theme-placeholderColor);height:26px!important;position:absolute;width:100%;z-index:-1}", ""]);

// exports
exports.locals = {
	"vd-min-height": "ImageSliderContentBlock__ImageSlider_vd-min-height",
	"vdMinHeight": "ImageSliderContentBlock__ImageSlider_vd-min-height"
};