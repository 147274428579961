exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.48.46.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__CustomPaging_dot-style{background:transparent;border:none;display:flex;width:auto}", ""]);

// exports
exports.locals = {
	"dot-style": "ImageSliderContentBlock__CustomPaging_dot-style",
	"dotStyle": "ImageSliderContentBlock__CustomPaging_dot-style"
};