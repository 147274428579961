exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.48.46.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__Image_image{bottom:0;height:100%;left:0;position:absolute;right:0;top:0;width:100%}.ImageSliderContentBlock__Image_image.ImageSliderContentBlock__Image_image-scale-best-view{-o-object-fit:cover;object-fit:cover}.ImageSliderContentBlock__Image_image.ImageSliderContentBlock__Image_image-scale-based-on-width{-o-object-fit:contain;object-fit:contain}.ImageSliderContentBlock__Image_image.ImageSliderContentBlock__Image_image-scale-based-on-height{-o-object-fit:fill;object-fit:fill}.ImageSliderContentBlock__Image_image.ImageSliderContentBlock__Image_image-scale-do-not-scale{height:unset;width:unset}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ImageSliderContentBlock__Image_image{transform:scaleY(1.4)}}", ""]);

// exports
exports.locals = {
	"image": "ImageSliderContentBlock__Image_image",
	"image-scale-best-view": "ImageSliderContentBlock__Image_image-scale-best-view",
	"imageScaleBestView": "ImageSliderContentBlock__Image_image-scale-best-view",
	"image-scale-based-on-width": "ImageSliderContentBlock__Image_image-scale-based-on-width",
	"imageScaleBasedOnWidth": "ImageSliderContentBlock__Image_image-scale-based-on-width",
	"image-scale-based-on-height": "ImageSliderContentBlock__Image_image-scale-based-on-height",
	"imageScaleBasedOnHeight": "ImageSliderContentBlock__Image_image-scale-based-on-height",
	"image-scale-do-not-scale": "ImageSliderContentBlock__Image_image-scale-do-not-scale",
	"imageScaleDoNotScale": "ImageSliderContentBlock__Image_image-scale-do-not-scale"
};